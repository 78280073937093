<template>
  <div>
    <v-card class="ma-4 pa-2" width="250">
      <v-btn
        color="blue"
        small
        :outlined="copiedNow"
        :text="!copiedNow"
        @click="copyLink"
        >{{ copiedNow ? "Now send to your friend :)" : "copy link" }}</v-btn
      >
      <v-btn color="blue" small text @click="makeSpacePublic"
        >Make Public</v-btn
      >

      <v-card-text>
        <!-- <div>{{ name }}</div> -->
        <p class="text-h4 text--primary">{{ name }}</p>
        <p v-if="inUserPod">In Your pod</p>
        <p v-else>External</p>

        <div class="text--primary">
          {{ description }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          block
          class="white--text text--bold"
          color="purple "
          @click="activateSpace"
          dense
          rounded
          outlined
        >
          Click to enter
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
// import Space from "@/models/Space";
export default {
  data() {
    return {
      copiedNow: false,
    };
  },
  props: ["name", "description", "url", "storagePath"],
  computed: {
    inUserPod() {
      return this.$props.storagePath?.includes(
        this.$store.getters["auth/podUrl"]
      );
    },
  },
  methods: {
    async makeSpacePublic() {
      this.$store.dispatch("spaces/setSpaceAsPublic", this.$props.storagePath);
    },
    copyLink() {
      console.log("copy-link", this.$props.url);
      navigator.clipboard.writeText(this.$props.url);
      this.copiedNow = true;

      setTimeout(() => {
        this.copiedNow = false;
      }, 3000);
    },
    async activateSpace() {
      // let space = await Space.find(this.$props?.url);
      let space = this.$store.state.spaces.availableSpaces[this.$props.url];
      if (space) {
        this.$store.dispatch("spaces/checkAndSetSpace", {
          space,
        });
      }
    },
  },
};
</script>
