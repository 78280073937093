<template>
  <div>
    <div class="container">
      <ToolBar />
      <portal-switch class="portal-switch" />
    </div>
    <v-container class="canvas pb-12 overflow-auto"> <slot /> </v-container>
  </div>
</template>
<script>
import ToolBar from "@/components/toolbar/ToolBar.vue";
import PortalSwitch from "@/components/toolbar/PortalSwitch.vue";

export default {
  components: { ToolBar, PortalSwitch },
};
</script>
<style>
.canvas {
  padding: 3em;
  margin-bottom: 100px;
  max-width: 1200px;
  max-height: 90% !important;
  overflow: hidden;
  background-color: rgba(252, 252, 252, 0.9);
}
</style>
