import { render, staticRenderFns } from "./SolidLogin.vue?vue&type=template&id=1fd07a56&"
import script from "./SolidLogin.vue?vue&type=script&lang=js&"
export * from "./SolidLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/common/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fvractal%2FFractopia%2Fsrc%2Fcomponents%2FSolidLogin.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VForm,VSheet,VTextField})
