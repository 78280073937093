<template>
  <v-container max-width="960px">
    <v-row>
      <v-col sm="12" md="5" lg="5" xl="3">
        <HiperList />
      </v-col>
      <v-col sm="12" md="7" lg="7" xl="9">
        <Note />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Note from "@/components/Note.vue";
import HiperList from "@/components/HiperList.vue";

export default {
  components: { Note, HiperList },
};
</script>
<style>
.canvas {
  padding: 3em;
  max-width: 1200px;
}
</style>
