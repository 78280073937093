<template>
  <div>
    <v-sheet class="d-flex justify-center text-center align-start">
      <h1>Your Spaces</h1>
    </v-sheet>
    <v-container
      max-width="960px"
      class="d-flex flex-wrap align-center justify-center"
    >
      <SpaceCard
        v-for="space in availableSpaces"
        v-bind:key="space.url"
        :name="space.name"
        :url="space.url"
        :storagePath="space.storagePath"
      />
      <v-sheet class="d-flex flex-row align-start flex-wrap justify-center">
        <v-card class="ma-4 pa-2" width="250">
          <v-card-text>
            <p class="text-h4 text--primary">Create Space</p>
            <div class="text">Then put stuff in it</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="white--text"
              v-if="!reveal"
              block
              color="purple "
              @click="reveal = true"
            >
              create
            </v-btn>
            <v-expand-transition>
              <div v-if="reveal">
                <v-text-field
                  placeholder="Enter name for your new space"
                  v-model="spaceNameInput"
                />
                <v-btn
                  block
                  class="white--text"
                  color="purple"
                  :loading="!!processing"
                  @click="createSpace"
                >
                  Confirm
                </v-btn>
              </div>
            </v-expand-transition>
          </v-card-actions>
        </v-card>

        <v-card class="ma-4 pa-2 d-flex flex-column align-between" width="250">
          <v-card-text>
            <p class="text-h5 text--primary">Link Shared Space</p>
            <div class="text">Experimental...</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="white--text"
              v-if="!revealExternalAdd"
              block
              color="purple "
              @click="revealExternalAdd = true"
            >
              Enter Link
            </v-btn>
            <v-expand-transition>
              <div v-if="revealExternalAdd">
                <v-text-field
                  placeholder="Enter link to Shared Space"
                  v-model="spaceUrlInput"
                />
                <v-btn
                  block
                  class="white--text"
                  color="purple"
                  :loading="!!processing"
                  @click="addExternalSpace"
                >
                  Confirm
                </v-btn>
              </div>
            </v-expand-transition>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import SpaceCard from "@/components/SpaceCard.vue";
import { map } from "lodash";

export default {
  mounted() {
    if (this.availableSpaces.length === 0) {
      this.$store.dispatch("spaces/loadSpaces");
    }
  },
  components: { SpaceCard },
  data() {
    return {
      reveal: false,
      revealExternalAdd: false,
      spaceNameInput: "",
      spaceUrlInput: "",
    };
  },
  computed: {
    processing() {
      return this.$store.state.spaces.processingStatus;
    },
    availableSpaces() {
      return map(this.$store.state.spaces.availableSpaces, (space) => space);
    },
  },
  methods: {
    createSpace() {
      this.$store.dispatch("spaces/createSpace", { name: this.spaceNameInput });
    },
    addExternalSpace() {
      this.$store.dispatch("spaces/addExternalSpace", this.spaceUrlInput);
    },
  },
};
</script>
<style>
.canvas {
  padding: 3em;
  max-width: 1200px;
}
</style>
