<template>
  <div>
    <FileTree />
  </div>
</template>
<script>
import FileTree from "@/components/FileTree.vue";

export default {
  components: { FileTree },
};
</script>
<style>
</style>
