const HiperFolder = "https://vocab.fractopia.org/portal"; // graph folders
// # Portal vocabularies
const Portal = "https://vocab.fractopia.org/portal";
const subPortal = "https://vocab.fractopia.org/rel/portal"; // graph folders

// Main screen you get when portal is active
const PortalInterface = "https://vocab.fractopia.org/PortalInterface";

const portalInterface = "https://vocab.fractopia.org/rel/portalInterface";
const defaultSubPortal = "https://vocab.fractopia.org/rel/defaultSubPortal";

const defaultLink = "https://vocab.fractopia.org/rel/defaultLink";
const ExternalSpaceReference =
  "https://vocab.fractopia.org/ExternalSpaceReference";
const externalSpaceUrl = "https://vocab.fractopia.org/rel/externalSpaceUrl";

const relations = {
  subPortal,
  externalSpaceUrl,
  portalInterface,
  defaultSubPortal,
  defaultLink,
};

const fractopia = {
  Portal,
  HiperFolder,
  PortalInterface,
  ExternalSpaceReference,
  relations,
};
export default fractopia;
